









































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Tooltip, Slider } from 'element-ui'

@Component({
  filters: {
    intervalTime(interval: number, format: string) {
      let operateTime = (time: number) => {
        return time < 10 ? '0' + time : '' + time
      }
      format = format || 'min:sec'
      return format
        .replace('min', operateTime(Math.floor(interval / 60)))
        .replace('sec', operateTime(Math.floor(interval % 60)))
    }
  },
  components: {
    [Tooltip.name]: Tooltip,
    [Slider.name]: Slider
  }
})
export default class cAudio extends Vue {
  @Prop({ type: String, default: 'http://duanshu-1253562005.cossh.myqcloud.com/2019/08/15/13/j4587306b30921gdeb/common/audio/1565846724593_216721.mp3' }) readonly url!: String
  @Prop({ type: Boolean, default: true }) readonly autoplay!: boolean
  @Prop({ type: Boolean, default: true }) readonly only!: boolean
  @Prop({ type: Boolean, default: false }) readonly hasPre!: boolean
  @Prop({ type: Boolean, default: false }) readonly hasNext!: boolean
  @Prop({ type: String, default: '' }) readonly barClass!: string

  volume: number = 100
  audioElm: any = {}
  playing: boolean = false
  audioDuration: number = 0
  audioCurrent: number = 0

  loading: boolean = false

  moving: number = 0
  startX: number = 0
  deltaX: number = 0
  defaultLeft: number = 0

  dotStyle: Object = {
    left: 0
  }
  errorAudio: boolean = false

  mounted () {
    this.loading = true
    this.audioElm = this.$refs.audioElm
    this.playInit()
    window.addEventListener('resize', this.updateDot.bind(this), false)
    window.document.body.addEventListener('mouseup', this.processTouchEnd.bind(this), false)
    window.document.body.addEventListener('mousemove', this.processTouchMove.bind(this), false)
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDot.bind(this), false)
    window.document.body.removeEventListener('mouseup', this.processTouchEnd.bind(this))
    window.document.body.removeEventListener('mousemove', this.processTouchMove.bind(this), false)
  }

  get barStyle() {
    let all = this.audioDuration || 1
    let cur = this.audioCurrent
    let width = cur / all * 100
    if (width > 100) {
      width = 100
    }
    return {
      width: `${width}%`
    }
  }

  @Watch('url')
  onUrlChanged(val: string, oldVal: string) {
    if (val) {
      this.playInit()
    } else {
      this.audioElm.pause()
    }
  }
  @Watch('audioCurrent', { immediate: true })
  onAudioCurrentChanged(val: string, oldVal: string) {
    this.updateDot()
    this.$emit('audioValueChange', { name: 'audioCurrent', value: this.audioCurrent })
  }
  @Watch('audioDuration', { immediate: true })
  onAudioDurationChanged(val: string, oldVal: string) {
    this.updateDot()
    this.$emit('audioValueChange', { name: 'audioDuration', value: this.audioDuration })
  }
  @Watch('moving', { immediate: true })
  onMovingChanged(val: string, oldVal: string) {
    this.$emit('audioValueChange', { name: 'moving', value: this.moving })
  }
  @Watch('playing', { immediate: true })
  onPlayingChanged(val: string, oldVal: string) {
    this.$emit('audioValueChange', { name: 'playing', value: this.playing })
  }
  @Watch('volume', { immediate: true })
  onVolumeChanged(val: string, oldVal: string) {
    this.changeVolumne()
  }

  _timeupdate() {
    if (!this.moving) {
      this.audioCurrent = this.audioElm.currentTime
    }
  }
  _loadedmetadata() {
    this.loading = false
    this.audioDuration = parseInt(this.audioElm.duration)
  }
  _play() {
    this.playing = true
  }
  _pause() {
    this.playing = false
  }
  _error(e: any) {
    this.loading = false
    this.errorAudio = true
    this.$emit('audioEvent', e)
  }
  _ended() {
    this.audioCurrent = 0
    this.$emit('ended')
  }
  updateDot() {
    this.$nextTick(() => {
      let $dot = this.$refs.dot as any
      let style = {} as any
      if ($dot) {
        let $cur = this.$refs.cur as any
        let $bar = this.$refs.bar as any
        let bW = $bar.offsetWidth
        let pW = $cur.offsetWidth
        let dW = $dot.offsetWidth
        if (pW <= dW / 2) {
          // 左边界
          style.left = 0
        } else if (pW >= (bW - dW / 2)) {
          // 右边界
          style.right = 0
        } else {
          style.left = `${pW - dW / 2}px`
        }
        this.dotStyle = style
      }
    })
  }
  playInit () {
    let src = this.url.replace('http://', 'https://')
    this.audioElm.src = src

    this.updateDot()
  }
  togglePlay() {
    if (!this.playing) {
      this.play()
    } else {
      this.pause()
    }
  }
  play() {
    if (this.errorAudio) {
      this.audioElm.src = this.getTimestampUrl()
    }
    this.audioElm.play().catch((e: any) => {
      if (e.name === 'NotAllowedError') {
        // 自动播放失败
      }
    })
  }
  pause() {
    this.audioElm.pause()
  }
  prev () {
    let prev = this.audioCurrent - 15
    if (prev < 0) {
      this.audioElm.currentTime = 0
    } else {
      this.audioElm.currentTime = prev
    }
  }
  next () {
    let next = this.audioCurrent + 15
    if (next < this.audioDuration) {
      this.audioElm.currentTime = next
    } else {
      this.audioElm.currentTime = this.audioDuration
    }
  }
  processClick (event: any) {
    let precent = event.layerX / (this.$refs.bar as any).offsetWidth
    this.audioElm.currentTime = Math.floor(this.audioDuration * precent)
  }
  processonClick (event: any) {
    let precent = event.layerX / (this.$refs.cur as any).offsetWidth
    this.audioElm.currentTime = Math.floor(this.audioElm.currentTime * precent)
    event.stopPropagation()
  }
  processTouchStart (event: any) {
    event.stopPropagation()
    this.startX = event.pageX
    this.defaultLeft = (this.$refs.cur as any).offsetWidth
    this.moving = 1
  }
  processTouchEnd (event: any) {
    if (this.moving) {
      let offsetWidth = (this.$refs.bar as any).offsetWidth
      let moveLeft = this.defaultLeft + this.deltaX
      if (moveLeft <= 0) {
        moveLeft = 0
      } else if (moveLeft >= offsetWidth) {
        moveLeft = offsetWidth
      }
      this.audioElm.currentTime = Math.floor(this.audioDuration * (moveLeft / offsetWidth))
      this.moving = 0
    }
  }
  processTouchMove (event: any) {
    if (this.moving) {
      this.deltaX = event.pageX - this.startX
      let offsetWidth = (this.$refs.bar as any).offsetWidth
      let moveLeft = this.defaultLeft + this.deltaX
      if (moveLeft <= 0) {
        moveLeft = 0
      } else if (moveLeft >= offsetWidth) {
        moveLeft = offsetWidth
      }
      this.audioCurrent = this.audioDuration * (moveLeft / offsetWidth)
    }
  }
  changeSpeed(times: number) {
    let defaultValue = (this.audioElm as any).defaultPlaybackRate
    ;(this.audioElm as any).playbackRate = defaultValue * times
  }
  changeVolumne() {
    (this.audioElm as any).volume = this.volume / 100
  }
  getTimestampUrl () {
    // 添加带有时间戳的链接
    let url = this.url.replace('http://', 'https://')
    return `${url}?timestamp=${+new Date()}`
  }
}
