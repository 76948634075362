






import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
})
export default class GVideoCopyrightMask extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  doing!: false
  @Prop({})
  videoBox!: null

  copyrightTimer: any = null

  @Watch('doing', {immediate: true})
  onDoingChanged(nVal: Boolean) {
    if (nVal) {
      this.$nextTick(() => {
        this.start()
      })
    } else {
      this.stop()
    }
  }

  get userDetail() {
    return this.$store.state.userInfo
  }

  get copyright() {
    return this.userDetail.id || '游客'
  }

  get enableCopyright() {
    return this.$store.getters.enableCopyright
  }

  mounted() {
    let thisBox: any = this.videoBox
    let videoBox = thisBox.querySelector('.vcp-player') || thisBox.querySelector('.tcplayer')
    if (videoBox && this.enableCopyright) {
      let box: any = this.$refs.copyrightMask
      videoBox.appendChild(box)
    }
  }

  beforeDestroy () {
    this.stop()
  }

  stop() {
    clearInterval(this.copyrightTimer)
  }

  start() {
    let box: any = this.$refs.copyrightMask
    let ball: any = this.$refs.copyright

    if (!this.enableCopyright) {
      return
    }
    this.stop()
    this.setPosition(box, ball)
    let speed = 0.04
    let max = 0.5
    this.copyrightTimer = setInterval(() => {
      let cur = parseFloat(ball.style.opacity) || 0
      let next
      next = cur + speed
      if (next >= max || next <= 0) {
        speed *= -1
      }
      ball.style.opacity = next
      if (next === 0) {
        this.setPosition(box, ball)
      }
    }, 200)
  }

  setPosition(box: any, ball: any) {
    let padding = 100
    let l = padding
    let t = padding
    let b = box.clientHeight - padding - ball.clientHeight
    let r = box.clientWidth - padding - ball.clientWidth
    ball.style.left = this.random(l, r) + 'px'
    ball.style.top = this.random(t, b) + 'px'
  }

  random(max: number, min: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
